<template>
  <component :is="commonComponent" id="privacy" title="Privacy policy">
    <section id="privacy-content" class="pc_padding">
      <div class="common-content-row">
        <ol>
          <li>
            At {{ domainConfig['IAmURL'] }}, we are committed to protecting your privacy. This
            policy outlines
            how we collect, use, and
            safeguard your information.<ol>
              <li>
                <strong>Information Collection</strong>: We may collect personal information such as
                your name and email
                address when you register or contact us.
              </li>
              <li>
                <strong>Use of Information</strong>: Your information is used to enhance your
                experience, respond to
                inquiries, and improve our services. We do not sell or share your personal
                information with third parties
                without your consent.
              </li>
              <li>
                <strong>Data Security</strong>: We implement appropriate security measures to
                protect your information from
                unauthorized access or disclosure.
              </li>
              <li>
                <strong>Contact Us</strong>: If you have any questions about our privacy
                practices, please email us at <a class="footer-item-content-item"
                  :href="`mailto:${domainConfig['IAMEMAIL']}`">{{ domainConfig['IAMEMAIL'] }}</a>.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Privacy policy | ${this.domainConfig["IAmURL"]}`,
      meta: [
        {
          name: 'description',
          content: `Access the Bible anytime with ${this.domainConfig['IAmURL']}. Enjoy seamless navigation for study and reflection.`,
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>